import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient,) { }

  logFromGroup : FormGroup;
  private destroy$: Subject<boolean>;

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  InsertExceptionLog(exception,className,methodName){
    this.logFromGroup = new FormGroup({
      Exception : new FormControl(exception),
      ClassName : new FormControl(className),
      MethodName : new FormControl(methodName),
      Sitename : new FormControl(location.href),
      Level : new FormControl('TsComponent')
    });

    this.InsertExceptionLogWebAPI(this.logFromGroup.value)
    .pipe(takeUntil(this.destroy$)).subscribe((value: any) => console.log(value));
  }

  InsertExceptionLogWebAPI(data): Observable<any>{
    const body = new HttpParams({ fromObject : data});
    const options = {headers : this.headers};
    return this.http.post(`${environment.APIUrl}Log/InsertExceptionLogAngular`, body, options);
  }

  InsertUserLogRecords(description : string){
    this.logFromGroup = new FormGroup({
      InstituteUserID : new FormControl(Number(sessionStorage.getItem('param4'))),
      Discription : new FormControl(description),
      UserType  : new FormControl(Number(sessionStorage.getItem('param2'))),
      IpAddress : new FormControl(sessionStorage.getItem("IP_ADDRESS"))
    });
    this.UserLogRecord(this.logFromGroup.value)
      .subscribe((result) => {
      }, (err: Error) => {
        console.log(err);
      });
  }

  UserLogRecord(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Log/InsertUserLogRecord`, body, options);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
