<div class="hold-transition theme-primary bg-img"
    style="background-image: url(../../../assets/images/auth-bg/bg-10.jpg);height: 100% !important;">
    <div class="content h-p100">
    <div class="row align-items-center justify-content-md-center h-p100">
        <div class="col-12 mt-5">
            <div  *ngIf=isResetSuccess class="row justify-content-center g-0">
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 right-panel get_start">
                            <div class="text-center">
                                <img src="../../../assets/images/Screenshot 2023-10-06 183720.png" alt="Center Image" style="height: 100px;width: 100px;">
                            </div>
                            <h4 class="text-primary text-center mt-2">Reset Your Password</h4>
                            Password must contain at least one lowercase letter, one uppercase letter, and one digit.
                            <div class="p-40">
                                <form form class="login-form" [formGroup]="checkPasswordForm">
                                    <div class="form-group">
                                        <div class="text-start">New Password:</div>
                                        <div class="input-group">
                                            <input #NewPassword
                                                type="{{ isPasswordVisible ? 'text' : 'password' }}"
                                                id="new-password"
                                                name="new-password"
                                                class="form-control"
                                                placeholder="Enter your new password"
                                                formControlName="newPassword"
                                                />
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="toggle-new-password" mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                                                    <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <p>Note : Your password must be at least 8 characters long</p>
                                        <!-- <div *ngIf="checkPasswordForm.get('newPassword').hasError('pattern')">
                                            Password must contain at least one lowercase letter, one uppercase letter, and one digit.
                                          </div> -->
                                        <div *ngIf="checkPasswordForm.get('newPassword').hasError('required')" class="asterisk">
                                            Password is required !!!
                                        </div>
                                        <div *ngIf="checkPasswordForm.get('newPassword').hasError('pattern')" class="asterisk">
                                            Your password should contain at least one capital & small case letter with minimum 8 character length
                                        </div>
                                    </div>
                                    <div class="form-group pt-2">
                                        <div class="text-start">Confirm Password:</div>
                                        <div class="input-group">
                                            <input #ConfirmPassword
                                                type="{{ isConfirmPasswordVisible ? 'text' : 'password' }}"
                                                id="confirm-password"
                                                name="confirm-password"
                                                class="form-control"
                                                placeholder="Enter your confirm-password"
                                                formControlName="confirmPassword"
                                                />
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="toggle-confirm-password"  mat-icon-button matSuffix (click)="toggleConfirmPasswordVisibility()">
                                                    <mat-icon>{{ isConfirmPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="checkPasswordForm.get('confirmPassword').hasError('required')" class="asterisk">
                                            Confirmation is required!!!
                                        </div>
                                        <div *ngIf="checkPasswordForm.get('confirmPassword').value !== '' && checkPasswordForm.get('newPassword').value !== '' && checkPasswordForm.get('confirmPassword').value !== checkPasswordForm.get('newPassword').value" class="asterisk">
                                            New password and Confirm Password should match !!
                                        </div> 
                                    </div>
                                    <div class="col-12 text-center mt-2">
                                        <button type="submit" class="btn btn-primary" (click)="resetPassword(ConfirmPassword.value)"
                                        [disabled]="!checkPasswordForm.valid || (checkPasswordForm.get('newPassword').value !== checkPasswordForm.get('confirmPassword').value) || (checkPasswordForm.get('newPassword').value.length < 8 || checkPasswordForm.get('confirmPassword').value.length < 8)">Reset Password</button>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="!isPasswordSame" class="asterisk">
                                Existing password is same as your new password !!
                            </div>
                            <a  [routerLink]="['/login']"><i class="fas fa-arrow-left"></i> Back to Login</a>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf=isResetPassword  class="row align-items-center justify-content-md-center h-p100 mt-5">
        <div class="col-12">
            <div class="row justify-content-center g-0">
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 right-panel get_start">
                            <div class="text-center">
                                <img src="../../../assets/images/password-success.png" alt="Center Image">
                            </div>
                            <h4 class="text-primary text-center mt-2">Password reset successfully</h4>
                            <p>Log In into your account with your new password</p>
                            <div class="p-40">
                                    <div class="col-12 text-center mt-2">
                                        <button type="submit"  [routerLink]="['/login']" class="btn btn-primary">Log In</button>
                                    </div>
                            </div>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf=isLinkExpired class="row align-items-center justify-content-md-center h-p100 mt-5">
        <div class="col-12">
            <div class="row justify-content-center g-0">
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 right-panel get_start">
                            <div class="text-center">
                                <img src="../../../assets/images/password-expired-icon.png" alt="Center Image"  style="height: 100px;width: 100px;">
                            </div>
                            <h4 class="text-primary text-center mt-2">Password reset failed</h4>
                            <p>Your password reset link has expired</p>
                            <div class="p-40">
                                    <div class="col-12 text-center mt-2">
                                        <button type="submit" class="btn btn-primary" [routerLink]="['/login']">Go to Login</button>
                                    </div>
                            </div>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

