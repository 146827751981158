import { Injectable } from '@angular/core';

declare const monaco: any;

@Injectable({
  providedIn: 'root'
})
export class MonacoEditorService {

  constructor() { }

  private editor: any;

  // initMonaco(): Promise<void> {
  //   return new Promise<void>((resolve) => {
  //     if (typeof monaco !== 'undefined') {
  //       // Already loaded
  //       resolve();
  //     } else {
  //       // Load AMD loader if not present
  //       const loaderScript = document.createElement('script');
  //       loaderScript.type = 'text/javascript';
  //       loaderScript.src = 'assets/monaco-editor/min/vs/loader.js';
  //       loaderScript.addEventListener('load', () => {
  //         (<any>window).require.config({ paths: { vs: 'assets/monaco-editor/min/vs' } });
  //         (<any>window).require(['vs/editor/editor.main'], () => {
  //           resolve();
  //         });
  //       });
  //       document.body.appendChild(loaderScript);
  //     }
  //   });
  // }

  // createEditor(container: HTMLElement, code: string, language: string): void {
  //   this.initMonaco().then(() => {
  //     if (this.editor) {
  //       this.editor.dispose();
  //     }

  //     this.editor = monaco.editor.create(container, {
  //       value: code,
  //       language: language,
  //     });
  //   });
  // }

  // disposeEditor(): void {
  //   if (this.editor) {
  //     this.editor.dispose();
  //   }
  // }
  

  // initMonaco(): Promise<void> {

  //   return new Promise<void>((resolve) => {
  //     if (typeof monaco !== 'undefined') {
  //       // Already loaded
  //       resolve();
  //       return;
  //     }

  //     const onGotAmdLoader = () => {
  //       // Load monaco
  //       (<any>window).require.config({ paths: { vs: 'assets/monaco-editor/min/vs' } });
  //       (<any>window).require(['vs/editor/editor.main'], () => {
  //         resolve();
  //       });
  //     };

  //     // Load AMD loader if not present
  //     if (!(<any>window).require) {
  //       const loaderScript = document.createElement('script');
  //       loaderScript.type = 'text/javascript';
  //       loaderScript.src = 'assets/monaco-editor/min/vs/loader.js';
  //       loaderScript.addEventListener('load', onGotAmdLoader);
  //       document.body.appendChild(loaderScript);
  //     } else {
  //       onGotAmdLoader();
  //     }
  //   });

  // }

  initMonaco(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        if (typeof monaco !== 'undefined') {
          // Already loaded
          resolve();
          return;
        }
  
        const onGotAmdLoader = () => {
          // Load monaco
          //(<any>window).require.config({ paths: { vs: 'assets/monaco-editor/min/vs' } });
          (<any>window).require.config({ paths: { vs: 'assets/monaco-editor/min/vs' } });
          (<any>window).require(['vs/editor/editor.main'], () => {
            resolve();
          });
        };
  
        // Load AMD loader if not present
        if (!(<any>window).require) {
          const loaderScript = document.createElement('script');
          loaderScript.type = 'text/javascript';
          loaderScript.src = 'assets/monaco-editor/min/vs/loader.js';
          loaderScript.addEventListener('load', onGotAmdLoader);
          document.body.appendChild(loaderScript);
        } else {
          onGotAmdLoader();
        }
      } catch (ex) {
        console.log(ex + " Exception in monaco service");
        reject(ex); // Reject the promise with the caught exception
      }
    });
  }
  
}
