<div class="hold-transition theme-primary bg-img"
    style="background-image: url(../../../assets/images/auth-bg/bg-10.jpg);height: 100% !important;">
    <div class="content h-p100">
    <div class="row align-items-center justify-content-md-center h-p100">
        <div class="col-12 mt-5">
            <div *ngIf="isForgotPass" class="row justify-content-center g-0" [formGroup]="forgotPasswordForm">
                <div class="mt-5">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 right-panel get_start">
                            <div class="text-center">
                                <img src="../../../assets/images/forgot-password.png" alt="Center Image" style="height: 100px;width: 100px;">
                            </div>
                            <h4 class="text-primary text-center mt-2">Forgot Password</h4>
                            <p>Enter the email you used to create your account so we can send you a link for resetting your password</p>
                            <div class="p-40">
                                <form form class="login-form" >
                                  <div class="text-start">Email</div>
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text bg-transparent">
                                                <i class="fa fa-envelope" aria-hidden="true"></i></span>
                                            <input #emailInput type="text" class="form-control ps-15 bg-transparent"
                                                formControlName="Email" placeholder="example@gmail.com">
                                        </div>
                                        <div *ngIf="!emailValidity"  class="asterisk">
                                            Enter a valid email id
                                        </div>
                                        <div *ngIf="!emailRegister"  class="asterisk">
                                            Your email id is not registered
                                        </div>
                                    </div>
                                    <div class="col-12 text-center mt-2">
                                        <button type="submit" class="btn btn-primary" [disabled]="isSendDisabled" (click)="sendEmail(emailInput.value)">Send</button>
                                    </div>
                                </form>
                            </div>
                            <a  [routerLink]="['/login']"><i class="fas fa-arrow-left"></i> Back to Login</a>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </div>
            </div>

            <div *ngIf=isEmailCheck class="row justify-content-center g-0 mt-5">
                <div class="mt-3">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 right-panel get_start">
                            <div class="text-center">
                                <img src="../../../assets/images/check_email.png" alt="Center Image" style="height: 100px;width: 100px;">
                            </div>
                            <h4 class="text-primary text-center mt-2">Check your email</h4>
                            <p>We have sent an email with password reset information </p>
                            <div class="p-40">
                                <div class="col-12 text-center mt-2">
                                    <button type="submit" class="btn btn-primary" [disabled]="isResetDisabled" (click)="onResendEmail()" >Resend Email</button>
                                </div>
                            </div>
                            <div *ngIf="!emailSent"  class="asterisk">
                                Email Sent !!!
                            </div>
                            <a  [routerLink]="['/login']"><i class="fas fa-arrow-left"></i> Back to Login</a>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
