import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ForgotPasswordService {

  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  forgetPasswordDataEklavvyaWebAPI(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    console.log(data);
    return this.http.post(`${environment.APIUrl}InstituteUser/ForgetPasswordData`, body, options);
  }

  getInstituteUserIDEklavvyaWebAPI(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    console.log(data);
    return this.http.post(`${environment.APIUrl}InstituteUser/GetInstituteUserID`, body, options);
  }
}
