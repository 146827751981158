import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/services/common/encryption.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdatePasswordService } from 'src/app/services/update-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  token : string;
  instituteUserEmailID : string;
  instituteUserID : string;
  timeSpan : string;
  interval : string;
  changeInstituteUserPasswordFormGroup: FormGroup;
  confirmPassword: string;
  checkPasswordForm: FormGroup;
  passwordsMatch: boolean = false;
  url : string;
  isPasswordSame: boolean = true;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  isResetPassword : boolean = false;
  isResetSuccess : boolean = true;
  isLinkExpired : boolean = false;

  constructor(private fb: FormBuilder, 
    private updatePasswordService: UpdatePasswordService, 
    private decrypt: EncryptionService, 
    private cookieService: CookieService, 
    private route: ActivatedRoute, 
    private router: Router) {}
  
  ngOnInit() {
    this.checkPasswordForm = this.fb.group({
      newPassword: ['', [
        Validators.required, 
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
      ]],
      confirmPassword: ['', [
        Validators.required
      ]]
    });
    try{
      this.route.queryParamMap.subscribe(param => {
          this.instituteUserEmailID = param.get('InstituteUserEmailID');
          this.instituteUserID = param.get('InstituteUserID');
          this.timeSpan = param.get('TimeSpan');
      });
      this.timeSpan = this.decrypt.decryptionAES(this.timeSpan.replace(/ /g, '+'));
      const timeDifferenceInMinutes = this.findTimeDifference()
      
      if (timeDifferenceInMinutes >= 60) {
        this.isResetSuccess = !this.isResetSuccess;
        this.isLinkExpired = !this.isLinkExpired;
      }
    }
    catch(error){
      alert(error);
    }
  }

  findTimeDifference(): number {
    const currentTime = new Date();
    const setDateTime = new Date();
    const setTime = this.timeSpan;
    const setTimeSplit = setTime.split(' ');
    const hours = setTimeSplit[4].split(':')[0];
    const minutes = setTimeSplit[4].split(':')[1];
    const seconds = setTimeSplit[4].split(':')[2];
    setDateTime.setHours(Number(hours), Number(minutes), Number(seconds));

    const timeDifferenceInMilliseconds = currentTime.getTime() - setDateTime.getTime();
    const timeDifferenceInMinutes = Math.round(timeDifferenceInMilliseconds / (1000 * 60));
    
    return timeDifferenceInMinutes;
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility(): void {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  resetPassword(confirmPassword){
    const timeDifferenceInMinutes = this.findTimeDifference()
    if (timeDifferenceInMinutes >= 60) {
      this.isResetSuccess = !this.isResetSuccess;
      this.isLinkExpired = !this.isLinkExpired;
    }
    else{
      this.changeInstituteUserPasswordEklavvyaWebAPI(confirmPassword);}
  }

  changeInstituteUserPasswordEklavvyaWebAPI(confirmPassword){
    this.changeInstituteUserPasswordFormGroup = new FormGroup({
      InstituteUserID : new FormControl(this.decrypt.decryptionAES(this.instituteUserID.replace(/ /g, '+'))),
      NewPassword : new FormControl(confirmPassword)
    });
    this.updatePasswordService.changeInstituteUserPasswordEklavvyaWebAPI(this.changeInstituteUserPasswordFormGroup.value).subscribe(
      (result) => {
        if(result.Data == true){
          this.isResetSuccess = !this.isResetSuccess;
          this.isResetPassword = !this.isResetPassword;
          this.cookieService.delete(this.decrypt.decryptionAES(this.timeSpan.replace(/ /g, '+')));    
        }
        else{
          this.isPasswordSame = false
        }
      })
  }
}
