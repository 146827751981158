import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

  encryptionAES (msg) {
    const ciphertext = CryptoTS.AES.encrypt(msg, environment.CookieEncryptionKey);
    return ciphertext.toString();
  }

  decryptionAES (msg) {
    const bytes  = CryptoTS.AES.decrypt(msg, environment.CookieEncryptionKey);
    const plaintext = bytes.toString(CryptoTS.enc.Utf8);
    return plaintext;
  }
}
