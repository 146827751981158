import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/services/common/encryption.service';
import { ForgotPasswordService } from 'src/app/services/forgot-password.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  isEmailCheck : boolean = false;
  isForgotPass : boolean = true;
  isSendClicked: boolean = false;
  getInstituteUserIDFormGroup: FormGroup;
  enInstituteUserEmailID: string;
  instituteUserEmailID: string;
  instituteID : number;
  fullName: string;
  forgotPasswordForm: FormGroup;
  forgotPasswordFormGroup: FormGroup;
  token: string;
  instituteUserID: string;
  timeSpan : string;
  emailValidity : boolean = true;
  emailRegister : boolean = true;
  emailSent : boolean = true;
  onResendClick : boolean = false;
  isResetDisabled : boolean = false;
  isSendDisabled : boolean = false;

  constructor(private fb: FormBuilder, 
    private cookieService: CookieService, 
    private forgotPasswordService: ForgotPasswordService, 
    private encrypt: EncryptionService) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]]
    });
  }
  
  get f() { return this.forgotPasswordFormGroup.controls; }

  sendEmail(emailInput){
    this.forgotPasswordForm.get('Email').setValue(emailInput);
    this.isSendClicked = true;
    this.isSendDisabled = true;
    this.instituteUserEmailID = emailInput;
    if(this.forgotPasswordForm.valid){
      this.emailValidity = true;
      this.getInstituteID();
    }
    else{
      this.emailValidity = false
    }
  }

  forgetPasswordDataEklavvyaWebAPI(){
    this.timeSpan = this.encrypt.encryptionAES(new Date().toString());

    this.forgotPasswordFormGroup = new FormGroup({
      EnInstituteUserEmailID : new FormControl(this.enInstituteUserEmailID),
      InstituteUserEmailID : new FormControl(this.instituteUserEmailID),
      InstituteUserID : new FormControl(this.instituteUserID),
      InstituteID : new FormControl(this.instituteID),
      TimeSpan : new FormControl(this.timeSpan),
      FullName : new FormControl(this.fullName)
    });
   
    this.forgotPasswordService.forgetPasswordDataEklavvyaWebAPI(this.forgotPasswordFormGroup.value).subscribe(
      (result) => {
        if(this.onResendClick && result.objStatusCode == 1){
          this.emailSent = false;
        }
        if(this.emailSent){
          this.isEmailCheck = !this.isEmailCheck;
          this.isForgotPass = !this.isForgotPass;
        }
        this.isResetDisabled = false;
        this.isSendDisabled = false;
      },
      (err:Error) => {
        console.log(err);
      }
      )
  }

  getInstituteID(){
    this.getInstituteUserIDFormGroup = new FormGroup({
      InstituteUserEmailID : new FormControl(this.instituteUserEmailID) 
    });
    this.forgotPasswordService.getInstituteUserIDEklavvyaWebAPI(this.getInstituteUserIDFormGroup.value).subscribe(
      (result) => {
        console.log(result);
        if(result.objStatusCode == 1){
          if(!result.Data.IsEmailValid)
          {
            this.emailRegister = false;
          }
          else{
            this.instituteUserID = this.encrypt.encryptionAES(result.Data.InstituteUserID.toString());
            this.enInstituteUserEmailID = this.encrypt.encryptionAES(this.instituteUserEmailID);
            this.instituteID = result.Data.InstituteID;
            this.fullName = result.Data.FullName;
            this.forgetPasswordDataEklavvyaWebAPI();
          } 
        }        
      },
      (err:Error) => {
        console.log(err);
      }
    );
  }

  onResendEmail(){
    this.onResendClick = true;
    this.isResetDisabled = true; 
    this.forgetPasswordDataEklavvyaWebAPI();
  }
}

