export interface InstituteRegistration {
}

export interface Country {
  code: string;
  name: string;
  phoneCode: string;
}
export const CountryList: Country[] = [
  { code: 'in', name: 'India', phoneCode: '+91' },
  { code: 'ad', name: 'Andorra', phoneCode: '+376' },
  { code: 'ae', name: 'United Arab Emirates', phoneCode: '+971' },
  { code: 'af', name: 'Afghanistan', phoneCode: '+93' },
  { code: 'ag', name: 'Antigua and Barbuda', phoneCode: '+1268' },
  { code: 'ai', name: 'Anguilla', phoneCode: '+1264' },
  { code: 'al', name: 'Albania', phoneCode: '+355' },
  { code: 'am', name: 'Armenia', phoneCode: '+374' },
  { code: 'an', name: 'Netherlands Antilles', phoneCode: '+599' },
  { code: 'ao', name: 'Angola', phoneCode: '+244' },
  { code: 'aq', name: 'Antarctica', phoneCode: '+672' },
  { code: 'ar', name: 'Argentina', phoneCode: '+54' },
  { code: 'as', name: 'American Samoa', phoneCode: '+684' },
  { code: 'at', name: 'Austria', phoneCode: '+43' },
  { code: 'au', name: 'Australia', phoneCode: '+61' },
  { code: 'aw', name: 'Aruba', phoneCode: '+297' },
  { code: 'ax', name: 'Aland Islands', phoneCode: '+358' },
  { code: 'az', name: 'Azerbaijan', phoneCode: '+994' },
  { code: 'ba', name: 'Bosnia and Herzegovina', phoneCode: '+387' },
  { code: 'bb', name: 'Barbados', phoneCode: '+1246' },
  { code: 'bd', name: 'Bangladesh', phoneCode: '+880' },
  { code: 'be', name: 'Belgium', phoneCode: '+32' },
  { code: 'bf', name: 'Burkina Faso', phoneCode: '+226' },
  { code: 'bg', name: 'Bulgaria', phoneCode: '+359' },
  { code: 'bh', name: 'Bahrain', phoneCode: '+973' },
  { code: 'bi', name: 'Burundi', phoneCode: '+257' },
  { code: 'bj', name: 'Benin', phoneCode: '+229' },
  { code: 'bm', name: 'Bermuda', phoneCode: '+1441' },
  { code: 'bn', name: 'Brunei Darussalam', phoneCode: '+673' },
  { code: 'bo', name: 'Bolivia', phoneCode: '+591' },
  { code: 'br', name: 'Brazil', phoneCode: '+55' },
  { code: 'bs', name: 'Bahamas', phoneCode: '+1242' },
  { code: 'bt', name: 'Bhutan', phoneCode: '+975' },
  { code: 'bw', name: 'Botswana', phoneCode: '+267' },
  { code: 'by', name: 'Belarus', phoneCode: '+375' },
  { code: 'bz', name: 'Belize', phoneCode: '+501' },
  { code: 'ca', name: 'Canada', phoneCode: '+1' },
  { code: 'cc', name: 'Cocos Keeling Islands', phoneCode: '+61' },
  { code: 'cd', name: 'Democratic Republic of the Congo', phoneCode: '+243' },
  { code: 'cf', name: 'Central African Republic', phoneCode: '+236' },
  { code: 'cg', name: 'Congo', phoneCode: '+242' },
  { code: 'ch', name: 'Switzerland', phoneCode: '+41' },
  { code: 'ci', name: 'Cote D\'Ivoire Ivory Coast', phoneCode: '+225' },
  { code: 'ck', name: 'Cook Islands', phoneCode: '+682' },
  { code: 'cl', name: 'Chile', phoneCode: '+56' },
  { code: 'cm', name: 'Cameroon', phoneCode: '+237' },
  { code: 'cn', name: 'China', phoneCode: '+86' },
  { code: 'co', name: 'Colombia', phoneCode: '+57' },
  { code: 'cr', name: 'Costa Rica', phoneCode: '+506' },
  { code: 'cs', name: 'Serbia and Montenegro', phoneCode: '+381' },
  { code: 'cu', name: 'Cuba', phoneCode: '+53' },
  { code: 'cv', name: 'Cape Verde', phoneCode: '+238' },
  { code: 'cx', name: 'Christmas Island', phoneCode: '+61' },
  { code: 'cy', name: 'Cyprus', phoneCode: '+90392' },
  { code: 'cz', name: 'Czech Republic', phoneCode: '+42' },
  { code: 'de', name: 'Germany', phoneCode: '+49' },
  { code: 'dj', name: 'Djibouti', phoneCode: '+253' },
  { code: 'dk', name: 'Denmark', phoneCode: '+45' },
  { code: 'dm', name: 'Dominica', phoneCode: '+1809' },
  { code: 'do', name: 'Dominican Republic', phoneCode: '+1809' },
  { code: 'dz', name: 'Algeria', phoneCode: '+213' },
  { code: 'ec', name: 'Ecuador', phoneCode: '+593' },
  { code: 'ee', name: 'Estonia', phoneCode: '+372' },
  { code: 'eg', name: 'Egypt', phoneCode: '+20' },
  { code: 'eh', name: 'Western Sahara', phoneCode: '+212' },
  { code: 'er', name: 'Eritrea', phoneCode: '+291' },
  { code: 'es', name: 'Spain', phoneCode: '+34' },
  { code: 'et', name: 'Ethiopia', phoneCode: '+251' },
  { code: 'fi', name: 'Finland', phoneCode: '+358' },
  { code: 'fj', name: 'Fiji', phoneCode: '+679' },
  { code: 'fk', name: 'Falkland Islands Malvinas', phoneCode: '+500' },
  { code: 'fm', name: 'Federated States of Micronesia', phoneCode: '+691' },
  { code: 'fo', name: 'Faroe Islands', phoneCode: '+298' },
  { code: 'fr', name: 'France', phoneCode: '+33' },
  { code: 'la', name: 'Laos', phoneCode: '+856' },
  { code: 'lb', name: 'Lebanon', phoneCode: '+961' },
  { code: 'lc', name: 'Saint Lucia', phoneCode: '+1758' },
  { code: 'li', name: 'Liechtenstein', phoneCode: '+417' },
  { code: 'lk', name: 'Sri Lanka', phoneCode: '+94' },
  { code: 'lr', name: 'Liberia', phoneCode: '+231' },
  { code: 'ls', name: 'Lesotho', phoneCode: '+266' },
  { code: 'lt', name: 'Lithuania', phoneCode: '+370' },
  { code: 'lu', name: 'Luxembourg', phoneCode: '+352' },
  { code: 'lv', name: 'Latvia', phoneCode: '+371' },
  { code: 'ly', name: 'Libya', phoneCode: '+218' },
  { code: 'ma', name: 'Morocco', phoneCode: '+212' },
  { code: 'mc', name: 'Monaco', phoneCode: '+377' },
  { code: 'md', name: 'Moldova', phoneCode: '+373' },
  { code: 'mg', name: 'Madagascar', phoneCode: '+261' },
  { code: 'mh', name: 'Marshall Islands', phoneCode: '+692' },
  { code: 'mk', name: 'Macedonia', phoneCode: '+389' },
  { code: 'ml', name: 'Mali', phoneCode: '+223' },
  { code: 'mm', name: 'Myanmar', phoneCode: '+95' },
  { code: 'mn', name: 'Mongolia', phoneCode: '+976' },
  { code: 'mo', name: 'Macao', phoneCode: '+853' },
  { code: 'mp', name: 'Northern Mariana Islands', phoneCode: '+1' },
  { code: 'mq', name: 'Martinique', phoneCode: '+596' },
  { code: 'mr', name: 'Mauritania', phoneCode: '+222' },
  { code: 'ms', name: 'Montserrat', phoneCode: '+1664' },
  { code: 'mt', name: 'Malta', phoneCode: '+356' },
  { code: 'mu', name: 'Mauritius', phoneCode: '+230' },
  { code: 'mv', name: 'Maldives', phoneCode: '+960' },
  { code: 'mw', name: 'Malawi', phoneCode: '+265' },
  { code: 'mx', name: 'Mexico', phoneCode: '+52' },
  { code: 'my', name: 'Malaysia', phoneCode: '+60' },
  { code: 'mz', name: 'Mozambique', phoneCode: '+258' },
  { code: 'na', name: 'Namibia', phoneCode: '+264' },
  { code: 'nc', name: 'New Caledonia', phoneCode: '+264' },
  { code: 'ne', name: 'Niger', phoneCode: '+227' },
  { code: 'nf', name: 'Norfolk Island', phoneCode: '+672' },
  { code: 'ng', name: 'Nigeria', phoneCode: '+234' },
  { code: 'ni', name: 'Nicaragua', phoneCode: '+505' },
  { code: 'nl', name: 'Netherlands', phoneCode: '+31' },
  { code: 'no', name: 'Norway', phoneCode: '+47' },
  { code: 'np', name: 'Nepal', phoneCode: '+977' },
  { code: 'nr', name: 'Nauru', phoneCode: '+674' },
  { code: 'nu', name: 'Niue', phoneCode: '+683' },
  { code: 'nz', name: 'New Zealand Aotearoa', phoneCode: '+64' },
  { code: 'om', name: 'Oman', phoneCode: '+968' },
  { code: 'pa', name: 'Panama', phoneCode: '+507' },
  { code: 'pe', name: 'Peru', phoneCode: '+51' },
  { code: 'pf', name: 'French Polynesia', phoneCode: '+689' },
  { code: 'pg', name: 'Papua New Guinea', phoneCode: '+675' },
  { code: 'ph', name: 'Philippines', phoneCode: '+63' },
  { code: 'pk', name: 'Pakistan', phoneCode: '+92' },
  { code: 'pl', name: 'Poland', phoneCode: '+48' },
  { code: 'pm', name: 'Saint Pierre and Miquelon', phoneCode: '+508' },
  { code: 'pn', name: 'Pitcairn', phoneCode: '+64' },
  { code: 'pr', name: 'Puerto Rico', phoneCode: '+1787' },
  { code: 'ps', name: 'Palestinian Territory', phoneCode: '+970' },
  { code: 'pt', name: 'Portugal', phoneCode: '+351' },
  { code: 'pw', name: 'Palau', phoneCode: '+680' },
  { code: 'py', name: 'Paraguay', phoneCode: '+595' },
  { code: 'qa', name: 'Qatar', phoneCode: '+974' },
  { code: 're', name: 'Reunion', phoneCode: '+262' },
  { code: 'ro', name: 'Romania', phoneCode: '+40' },
  { code: 'ru', name: 'Russian Federation', phoneCode: '+7' },
  { code: 'rw', name: 'Rwanda', phoneCode: '+250' },
  { code: 'sa', name: 'Saudi Arabia', phoneCode: '+966' },
  { code: 'sb', name: 'Solomon Islands', phoneCode: '+677' },
  { code: 'sc', name: 'Seychelles', phoneCode: '+248' },
  { code: 'sd', name: 'Sudan', phoneCode: '+249' },
  { code: 'se', name: 'Sweden', phoneCode: '+46' },
  { code: 'sg', name: 'Singapore', phoneCode: '+65' },
  { code: 'sh', name: 'Saint Helena', phoneCode: '+290' },
  { code: 'si', name: 'Slovenia', phoneCode: '+386' },
  { code: 'sj', name: 'Svalbard and Jan Mayen', phoneCode: '+47' },
  { code: 'sk', name: 'Slovakia', phoneCode: '+421' },
  { code: 'sl', name: 'Sierra Leone', phoneCode: '+232' },
  { code: 'sm', name: 'San Marino', phoneCode: '+378' },
  { code: 'sn', name: 'Senegal', phoneCode: '+221' },
  { code: 'so', name: 'Somalia', phoneCode: '+252' },
  { code: 'sr', name: 'Suriname', phoneCode: '+597' },
  { code: 'st', name: 'Sao Tome and Principe', phoneCode: '+239' },
  { code: 'sv', name: 'El Salvador', phoneCode: '+503' },
  { code: 'sy', name: 'Syria', phoneCode: '+963' },
  { code: 'sz', name: 'Swaziland', phoneCode: '+268' },
  { code: 'tc', name: 'Turks and Caicos Islands', phoneCode: '+1' },
  { code: 'td', name: 'Chad', phoneCode: '+235' },
  { code: 'tg', name: 'Togo', phoneCode: '+228' },
  { code: 'th', name: 'Thailand', phoneCode: '+66' },
  { code: 'tj', name: 'Tajikistan', phoneCode: '+992' },
  { code: 'tk', name: 'Tokelau', phoneCode: '+690' },
  { code: 'tl', name: 'Timor-Leste', phoneCode: '+670' },
  { code: 'tm', name: 'Turkmenistan', phoneCode: '+7' },
  { code: 'tn', name: 'Tunisia', phoneCode: '+216' },
  { code: 'to', name: 'Tonga', phoneCode: '+676' },
  { code: 'tp', name: 'East Timor', phoneCode: '+670' },
  { code: 'tr', name: 'Turkey', phoneCode: '+90' },
  { code: 'tt', name: 'Trinidad and Tobago', phoneCode: '+868' },
  { code: 'tv', name: 'Tuvalu', phoneCode: '+688' },
  { code: 'tw', name: 'Taiwan', phoneCode: '+886' },
  { code: 'tz', name: 'Tanzania', phoneCode: '+255' },
  { code: 'ua', name: 'Ukraine', phoneCode: '+380' },
  { code: 'ug', name: 'Uganda', phoneCode: '+256' },
  { code: 'uk', name: 'United Kingdom', phoneCode: '+44' },
  { code: 'um', name: 'United States Minor Outlying Islands', phoneCode: '+246' },
  { code: 'us', name: 'United States', phoneCode: '+1' },
  { code: 'uy', name: 'Uruguay', phoneCode: '+598' },
  { code: 'uz', name: 'Uzbekistan', phoneCode: '+7' },
  { code: 'va', name: 'Vatican City State Holy See', phoneCode: '+379' },
  { code: 'vc', name: 'Saint Vincent and the Grenadines', phoneCode: '+1784' },
  { code: 've', name: 'Venezuela', phoneCode: '+58' },
  { code: 'vg', name: 'Virgin Islands British', phoneCode: '+1284' },
  { code: 'vi', name: 'Virgin Islands U.S.', phoneCode: '+1340' },
  { code: 'vn', name: 'Viet Nam', phoneCode: '+84' },
  { code: 'vu', name: 'Vanuatu', phoneCode: '+678' },
  { code: 'wf', name: 'Wallis and Futuna', phoneCode: '+681' },
  { code: 'ws', name: 'Samoa', phoneCode: '+685' },
  { code: 'ye', name: 'Yemen', phoneCode: '+967' },
  { code: 'yt', name: 'Mayotte', phoneCode: '+269' },
  { code: 'za', name: 'South Africa', phoneCode: '+27' },
  { code: 'zm', name: 'Zambia', phoneCode: '+260' },
  { code: 'zw', name: 'Zimbabwe', phoneCode: '+263' },

];

