/* Declarations from Node Modules */
import { Component, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

/* Declarations from Services */
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/services/common/encryption.service';
import { AuthService } from 'src/app/services/common/auth.service';

/* Declarations from Environment */
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  ErrorMessage: string;
  formGroup: FormGroup;
  gcpFormGroup: FormGroup;
  googleGroup: FormGroup;
  formPrgGroup: FormGroup;
  hide = true;
  IsRegistration = 1;
  programmingToken: string;

  isRole = 0;
  isUsername = 0;
  isPassword = 0;
  isInvalidLogin = 0;
  reCaptchaVerfication = null;
  isPasswordVisible: boolean = false;
  spinnerText: string = '';
  isNewInstituteUser: boolean = false

  CaptchaSiteKey = environment.recaptcha.siteKey;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  private destroy$ = new Subject<void>();

  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private encrypt: EncryptionService,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    try {
      var token = "", InstituteID = "", usertype = "", instituteUserID = "", userName = "", passWord = "";
      this.route.queryParamMap.subscribe((params: any) => {
        token = params.get('token');
        InstituteID = params.get('InstituteID')
        usertype = params.get('UserType');
        instituteUserID = params.get('InstituteUserID');
      });

      this.route.params.subscribe(params => {
        userName = params['username'];
        passWord = params['password'];
      });

      if (userName != null ) {
        if (userName != "") {
          this.IsRegistration = 0;
          this.formGroup = new FormGroup({
            Role: new FormControl(1),
            Username: new FormControl(userName),
            Password: new FormControl(passWord),
            rememberMe: new FormControl(),
            isGoogle : new FormControl('1')
          });
          this.spinner.show();
          this.isNewInstituteUser = true;
          this.spinnerText = 'Creating New Account. Please Wait...';
          this.Userlogin(1);
        }
        else {
          this.initForm();
          if (this.authService.IsLoggedIn()) {
            this.router.navigate(['admin/home']);
          }
        }
      }
      else if (token != null) {
        this.IsRegistration = 0;
        this.authService.setToken(token);
        sessionStorage.setItem('InstituteID', InstituteID);
        sessionStorage.setItem('UserType', usertype);
        sessionStorage.setItem('InstituteUserID', instituteUserID);
        this.router.navigate(['admin/home']);
      }
      else {
        var username = this.cookieService.get('Username');
        var password = this.cookieService.get('Password');
        var role = this.cookieService.get('Role');

        if (username != "" && password != "" && role != "") {
          this.formGroup = new FormGroup({
            Role: new FormControl(this.encrypt.decryptionAES(role)),
            Username: new FormControl(this.encrypt.decryptionAES(username)),
            Password: new FormControl(this.encrypt.decryptionAES(password)),
            rememberMe: new FormControl(),
            isGoogle : new FormControl()
          });

          this.Userlogin(1);
        }
        else {
          this.initForm();
          if (this.authService.IsLoggedIn()) {
            this.router.navigate(['admin/home']);
          }
        }
      }

      if (this.IsRegistration == 1) {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: "854378013722-4aibrpi7r94q6aokfpbtr9kckgspt6sv.apps.googleusercontent.com",
          callback: this.handleCredentialResponse.bind(this),
          use_fedcm_for_prompt: true,
          auto_select: false,
          cancel_on_tap_outside: true,
        });

        // @ts-ignore
        google.accounts.id.renderButton(
          document.getElementById("google-button"),
          {
            theme: "outline",
            size: "medium",
            width: "100%",
            text: "Google", // Custom text on the button
            shape: "rectangular", // Button shape: 'rectangular' or 'pill'
            isSignedIn: false,    // Display the button only if the user is not signed in
            prompt: "select_account", // Prompt user to select an account
          }
        );

        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => { });
      }

    } catch (ex) {
      console.log("Error in ngOnInit : " + ex);
    }
  }

  ngAfterViewInit() {
    if (this.IsRegistration == 1) {

      // @ts-ignore
      google.accounts.id.initialize({
        client_id: "854378013722-4aibrpi7r94q6aokfpbtr9kckgspt6sv.apps.googleusercontent.com",
        callback: this.handleCredentialResponse.bind(this),
        use_fedcm_for_prompt: true,
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("google-button"),
        {
          theme: "outline",
          size: "medium",
          width: "100%",
          text: "Google", // Custom text on the button
          shape: "rectangular", // Button shape: 'rectangular' or 'pill'
          isSignedIn: false,    // Display the button only if the user is not signed in
          prompt: "select_account", // Prompt user to select an account
        }
      );

      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    }
  }

  // Google Sign in
  async handleCredentialResponse(response: any) {
    try {
      if (response.credential) {
        const idToken = response.credential;
        const decodedToken = this.decodeIdToken(idToken);

        if (decodedToken) {
          const userEmail = decodedToken.email;
          const usrRole = this.formGroup.value.Role;
          
          this.formGroup = new FormGroup({
            Role: new FormControl(usrRole),
            Username: new FormControl(userEmail),
            Password: new FormControl(""),
            rememberMe: new FormControl(),
            isGoogle:new FormControl("1")
          });
          this.Userlogin(1);
        }
      }
    } catch (ex) {
      console.log("Error in handleCredentialResponse : " + ex);
    }
  }

  decodeIdToken(idToken: string) {
    try {
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      return payload;
    } catch (error) {
      console.error("Error in decodeIdToken : ", error);
      return null;
    }
  }

  initForm() {
    try {
      this.formGroup = new FormGroup({
        Role: new FormControl("1"),
        Username: new FormControl("", [Validators.required]),
        Password: new FormControl("", [Validators.required]),
        rememberMe: new FormControl(),
        isGoogle : new FormControl()
      });

      this.isRole = 0;
      this.isUsername = 0;
      this.isPassword = 0;
      this.isInvalidLogin = 0;
      this.reCaptchaVerfication = null;
    } catch (ex) {
      console.log("Error in initForm : " + ex);
    }
  }

  // login method
  Userlogin(IsCookieLogin?) {
    try {
      if (!this.isNewInstituteUser) {
        this.spinnerText = 'Logging in...'
      }
      this.spinner.show();
      this.isInvalidLogin = 0;
      if (this.reCaptchaVerfication == null && IsCookieLogin == undefined) {
        alert("Please tick I am not a robot!");
        this.spinner.hide();
      }
      else if (this.formGroup.value.Username == "" || this.formGroup.value.Username == null) {
        this.isUsername = 1;
        this.spinner.hide();
      }
      else if ((this.formGroup.value.Password == "" || this.formGroup.value.Password == null) && IsCookieLogin == undefined) {
        this.isPassword = 1;
        this.spinner.hide();
      }
      else if (this.formGroup.value.Role == 0) {
        this.isRole = 1;
        this.spinner.hide();
      }
      else {
        this.authService.login(this.formGroup.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (result) => {
              if (result.objStatusCode == 1 && result.Data.Token != null && result.Data.Token != "") {
                const subscriptionEndDate = new Date(result.Data.SubscriptionEndDate);
                const difference = Math.floor((subscriptionEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
                if (difference <= 10 && difference > 0) {
                  Swal.fire({
                    title: 'Caution',
                    text: `Your subscription would end in ${difference} days`,
                    icon: 'warning'
                  })
                }
                else if (difference == 0) {
                  Swal.fire({
                    title: 'Caution',
                    text: `Your subscription will end today`,
                    icon: 'warning'
                  })
                }
                else if (difference < 0) {
                  Swal.fire({
                    title: 'Caution',
                    text: `Subscription End Date is elapsed. Please Renew Your Subscription Today.`,
                    icon: 'warning'
                  })
                }
                //sessionStorage.setItem('MasterInstituteID', result.Data.InstituteID);
                this.authService.setAllSessions(result, this.formGroup.value.Username);

                if (this.formGroup.value.rememberMe == true) {
                  this.cookieService.set('Username', this.encrypt.encryptionAES(this.formGroup.value.Username));
                  this.cookieService.set('Password', this.encrypt.encryptionAES(this.formGroup.value.Password));
                  this.cookieService.set('Role', this.encrypt.encryptionAES(this.formGroup.value.Role));
                }

                //this.router.navigate(['admin/home']);
                this.setDefaultRedirection();
              }
              else {
                this.isInvalidLogin = 1;
                this.ErrorMessage = "Invalid Username or Password."
                this.spinner.hide();
              }
              this.spinner.hide();
            }, (err: Error) => {
              console.log(err.message);
              this.spinner.hide();
            });
      }
    } catch (ex) {
      console.log("Error in Userlogin : " + ex);
      this.spinner.hide();
    }
  }

  setDefaultRedirection() {
    try {
      if (sessionStorage.getItem('UserType') == "19") {
        this.router.navigate(['admin/proctor/proctor-dashboard']);
      }
      else if (sessionStorage.getItem('UserType') == "13") {
        this.router.navigate(['admin/daily-exam-status']);
      }
      else if (sessionStorage.getItem('UserType') == "7") {
        this.router.navigate(['admin/daily-exam-status']);
      }
      else {
        this.router.navigate(['admin/home']);
      }
    }
    catch (ex) {
      console.log("Error in sidebar setDefaultRedirection" + ex);
    }
  }

  isEndDateGreaterThanToday(endDateString: string): boolean {
    const endDate = new Date(endDateString);
    const currentDate = new Date();
    return endDate > currentDate;
  }

  handleSuccess(data) {
    this.reCaptchaVerfication = data;
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}