import { Injectable } from '@angular/core';

import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  constructor(
    private logservice: LogService,) { }

  AllowAudioRecognition: string;
  BranchWiseLogoUpload: string;
  InstituteUserEmailID: string;
  InstituteUserID: string
  IsValid: string
  UserName: string
  UserType: string
  LastLogin: string
  InstituteID: string
  InstituteName: string
  PackageID: string
  SubscriptionEndDate: string
  Plan_Expiry_Date_At: string
  CategoryID: string
  DateDiffCount: string
  LogoUrl: string
  InstituteLogo: string
  QuestionCount: string
  No_of_Exams_Created: string
  No_of_Subjects_Created: string
  No_of_Topics_Created: string
  Total_Exams_Assigned: string
  Total_Exam_Schedules: string
  UsersAdded: string
  created_at: string
  Plan: string
  ExamSessionsConducted: string
  InstituteMobile: string
  IsDataEncrypted: string
  IsInstituteHasBranches: string
  Isparagraphbased: string
  IsUnlimitedExam: string
  IsAutoProctored: string
  IsPDFDownload: string
  IsInstituteHasImageBasedOptions: string
  IsInstituteHasExamCode: string
  IsMathJaxReports: string
  ExamMonthAvailable: string
  IsDifficultyLevelwiseResult: string
  IsMultiselect: string
  IsSurveyLinkEnable: string
  IsSendCertificate: string
  Isknowledgebasestreaming: string
  IsAPIEnabled: string
  IsHtmlReports: string
  IsMobileOptional: string
  IsEmailActivation: string
  IsDistanceLearning: string
  LogoDimension: string
  IsNewCertificate: string
  IsInstituteHasGraceMarks: string
  KnowledgeSharing: string
  IsSchoolStructure: string
  IsInstituteHasReAttemptCount: string
  IsBulkApprove: string
  IsWhiteLabel: string
  BranchID: string
  Iscandidatenameshowtoexaminer: string
  IsShowOldReviewReport: string
  IsReGenerateResultEnabled: string
  IsEnabledArchiveDB: string
  AllowSaveStreaming: string
  IsMoodleIntegrationEnabled: string
  IsProgrammingQues: string
  IsInstituteHasRandomDates: string
  CloudType: string
  HeaderBGColor: string
  HeaderLabelColor: string
  LoginWithDocumentProof: string
  Isway2communication: string
  TodaysExamStats: string
  IsAllowProfPhotoCompare: string
  IsMUInstitute: string
  IsAdvanceAISetting: string
  IsOldAI: string
  IsMisReport: string
  Zoho_CustomerID: string
  IsAutoGeneratedQue: string
  IsAutoEvaluation:string
  IsFeedBack: string
  RoleName: string
  isPartialMarksForMTP: string
  IsSkillSet: string
  Signup_Process_Completed: string
  Payment_Done: string
  IsLatexEditor: string
  AIQuestionCredit: string
  IsExamwiseLogo: string
  IsAllowPsychometricQuestion: string
  ShowRankingDashboard: string
  IsGenerativeAI: string
  SubscriptionStartDate: string

  getDataForSession(data, instituteData, userName?): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.BranchWiseLogoUpload = data.BranchWiseLogoUpload;
        this.IsAutoEvaluation=data.IsAutoEvaluation;
        this.SubscriptionStartDate = instituteData.SubscriptionStartDate;
        this.InstituteUserEmailID = instituteData.Email;
        this.InstituteLogo = instituteData.LogoPath;
        this.AllowAudioRecognition = data.AllowAudioRecognition
        this.InstituteUserID = data.InstituteUserID;
        this.IsValid = data.IsValid;
        if (userName != undefined && userName != null && userName != "" && userName != "null") {
          this.UserName = userName
        } else {
          this.UserName = data.UserName
        }
        this.UserType = data.UserType
        this.LastLogin = data.LastLogin;
        this.InstituteID = data.InstituteID;
        this.InstituteName = data.InstituteName;
        this.PackageID = data.PackageID;
        this.SubscriptionEndDate = data.SubscriptionEndDate;
        this.Plan_Expiry_Date_At = data.Plan_Expiry_Date_At;
        this.CategoryID = data.CategoryID;
        this.DateDiffCount = data.DateDiffCount;
        this.LogoUrl = data.LogoUrl;
        this.QuestionCount = data.QuestionCount;
        this.No_of_Exams_Created = data.No_of_Exams_Created;
        this.No_of_Subjects_Created = data.No_of_Subjects_Created;
        this.No_of_Topics_Created = data.No_of_Topics_Created;
        this.Total_Exams_Assigned = data.Total_Exams_Assigned;
        this.Total_Exam_Schedules = data.Total_Exam_Schedules;
        this.UsersAdded = data.UsersAdded;
        this.created_at = data.created_at;
        this.Plan = data.Plan;
        this.ExamSessionsConducted = data.ExamSessionsConducted;
        this.InstituteMobile = data.InstituteMobile;
        this.IsDataEncrypted = data.IsDataEncrypted;
        this.IsInstituteHasBranches = data.IsInstituteHasBranches;
        this.Isparagraphbased = data.Isparagraphbased;
        this.IsUnlimitedExam = data.IsUnlimitedExam;
        this.IsAutoProctored = data.IsAutoProctored;
        this.IsPDFDownload = data.IsPDFDownload;
        this.IsInstituteHasImageBasedOptions = data.IsInstituteHasImageBasedOptions;
        this.IsInstituteHasExamCode = data.IsInstituteHasExamCode;
        this.IsMathJaxReports = data.IsMathJaxReports;
        this.ExamMonthAvailable = data.ExamMonthAvailable;
        this.IsDifficultyLevelwiseResult = data.IsDifficultyLevelwiseResult;
        this.IsMultiselect = data.IsMultiselect;
        this.IsSurveyLinkEnable = data.IsSurveyLinkEnable;
        this.IsSendCertificate = data.IsSendCertificate;
        this.Isknowledgebasestreaming = data.Isknowledgebasestreaming;
        this.IsAPIEnabled = data.IsAPIEnabled;
        this.IsHtmlReports = data.IsHtmlReports;
        this.IsMobileOptional = data.IsMobileOptional;
        this.IsEmailActivation = data.IsEmailActivation;
        this.IsDistanceLearning = data.IsDistanceLearning;
        this.LogoDimension = data.LogoDimension;
        this.IsNewCertificate = data.IsNewCertificate;
        this.IsInstituteHasGraceMarks = data.IsInstituteHasGraceMarks;
        this.KnowledgeSharing = data.KnowledgeSharing;
        this.IsSchoolStructure = data.IsSchoolStructure;
        this.IsInstituteHasReAttemptCount = data.IsInstituteHasReAttemptCount;
        this.IsBulkApprove = data.IsBulkApprove;
        this.IsWhiteLabel = data.IsWhiteLabel;
        this.BranchID = data.BranchID;
        this.Iscandidatenameshowtoexaminer = data.Iscandidatenameshowtoexaminer;
        this.IsShowOldReviewReport = data.IsShowOldReviewReport;
        this.IsReGenerateResultEnabled = data.IsReGenerateResultEnabled;
        this.IsEnabledArchiveDB = data.IsEnabledArchiveDB;
        this.AllowSaveStreaming = data.AllowSaveStreaming;
        this.IsMoodleIntegrationEnabled = data.IsMoodleIntegrationEnabled;
        this.IsProgrammingQues = data.IsProgrammingQues;
        this.IsInstituteHasRandomDates = data.IsInstituteHasRandomDates;
        this.CloudType = data.CloudType;
        this.HeaderBGColor = data.HeaderBGColor;
        this.HeaderLabelColor = data.HeaderLabelColor;
        this.LoginWithDocumentProof = data.LoginWithDocumentProof;
        this.Isway2communication = data.Isway2communication;
        this.TodaysExamStats = data.TodaysExamStats;
        this.IsAllowProfPhotoCompare = data.IsAllowProfPhotoCompare;
        this.IsMUInstitute = data.IsMUInstitute;
        this.IsAdvanceAISetting = data.IsAdvanceAISetting;
        this.IsOldAI = data.IsOldAI;
        this.IsMisReport = data.IsMisReport;
        this.Zoho_CustomerID = data.Zoho_CustomerID;
        this.IsAutoGeneratedQue = data.IsAutoGeneratedQue;
        this.IsFeedBack = data.IsFeedBack;
        this.RoleName = data.RoleName;
        this.isPartialMarksForMTP = data.isPartialMarksForMTP;
        this.IsSkillSet = data.IsSkillSet;
        this.Signup_Process_Completed = data.Signup_Process_Completed;
        this.Payment_Done = data.Payment_Done;
        this.IsLatexEditor = data.IsLatexEditor;
        this.AIQuestionCredit = data.AIQuestionCredit;
        this.IsExamwiseLogo = data.IsExamwiseLogo;
        this.IsAllowPsychometricQuestion = data.IsAllowPsychometricQuestion;
        this.ShowRankingDashboard = data.ShowRankingDashboard;
        this.IsGenerativeAI = data.IsGenerativeAI;

        resolve();
      } catch (ex) {
        this.logservice.InsertExceptionLog(ex, 'Data Store Service', 'getDataForSession');
        console.log("Error in getDataForSession : " + ex);
        reject(ex);
      }
    });
  }

  setData(dataName: string, dataValue) {
    try {
      if (dataName === 'AllowAudioRecognition') {
        this.AllowAudioRecognition = dataValue;
      } else if (dataName === 'SubscriptionStartDate') {
        this.SubscriptionStartDate = dataValue;
      }else if (dataName === 'IsAutoEvaluation') {
        this.IsAutoEvaluation = dataValue;
      }
      else if (dataName === 'InstituteUserEmailID') {
        this.InstituteUserEmailID = dataValue;
      }
       else if (dataName === 'InstituteLogo') {
        this.InstituteLogo = dataValue;
      }
      else if (dataName === 'BranchWiseLogoUpload') {
        this.BranchWiseLogoUpload = dataValue;
      }
      else if (dataName === 'InstituteUserID') {
        this.InstituteUserID = dataValue;
      } else if (dataName === 'IsValid') {
        this.IsValid = dataValue;
      } else if (dataName === 'UserName') {
        this.UserName = dataValue;
      } else if (dataName === 'UserType') {
        this.UserType = dataValue;
      } else if (dataName === 'LastLogin') {
        this.LastLogin = dataValue;
      } else if (dataName === 'InstituteID') {
        this.InstituteID = dataValue;
      } else if (dataName === 'InstituteName') {
        this.InstituteName = dataValue;
      } else if (dataName === 'PackageID') {
        this.PackageID = dataValue;
      } else if (dataName === 'SubscriptionEndDate') {
        this.SubscriptionEndDate = dataValue;
      } else if (dataName === 'Plan_Expiry_Date_At') {
        this.Plan_Expiry_Date_At = dataValue;
      } else if (dataName === 'CategoryID') {
        this.CategoryID = dataValue;
      }
      else if (dataName === 'DateDiffCount') {
        this.DateDiffCount = dataValue;
      }
      else if (dataName === 'LogoUrl') {
        this.LogoUrl = dataValue;
      }
      else if (dataName === 'QuestionCount') {
        this.QuestionCount = dataValue;
      }
      else if (dataName === 'No_of_Exams_Created') {
        this.No_of_Exams_Created = dataValue;
      }
      else if (dataName === 'No_of_Subjects_Created') {
        this.No_of_Subjects_Created = dataValue;
      } else if (dataName === 'No_of_Topics_Created') {
        this.No_of_Topics_Created = dataValue;
      } else if (dataName === 'Total_Exams_Assigned') {
        this.Total_Exams_Assigned = dataValue;
      } else if (dataName === 'Total_Exam_Schedules') {
        this.Total_Exam_Schedules = dataValue;
      } else if (dataName === 'UsersAdded') {
        this.UsersAdded = dataValue;
      } else if (dataName === 'Plan') {
        this.Plan = dataValue;
      } else if (dataName === 'ExamSessionsConducted') {
        this.ExamSessionsConducted = dataValue;
      } else if (dataName === 'InstituteMobile') {
        this.InstituteMobile = dataValue;
      } else if (dataName === 'IsDataEncrypted') {
        this.IsDataEncrypted = dataValue;
      } else if (dataName === 'IsInstituteHasBranches') {
        this.IsInstituteHasBranches = dataValue;
      } else if (dataName === 'Isparagraphbased') {
        this.Isparagraphbased = dataValue;
      } else if (dataName === 'IsUnlimitedExam') {
        this.IsUnlimitedExam = dataValue;
      } else if (dataName === 'IsAutoProctored') {
        this.IsAutoProctored = dataValue;
      } else if (dataName === 'IsPDFDownload') {
        this.IsPDFDownload = dataValue;
      } else if (dataName === 'IsInstituteHasImageBasedOptions') {
        this.IsInstituteHasImageBasedOptions = dataValue;
      } else if (dataName === 'IsInstituteHasExamCode') {
        this.IsInstituteHasExamCode = dataValue;
      } else if (dataName === 'IsMathJaxReports') {
        this.IsMathJaxReports = dataValue;
      } else if (dataName === 'ExamMonthAvailable') {
        this.ExamMonthAvailable = dataValue;
      } else if (dataName === 'IsDifficultyLevelwiseResult') {
        this.IsDifficultyLevelwiseResult = dataValue;
      } else if (dataName === 'IsMultiselect') {
        this.IsMultiselect = dataValue;
      } else if (dataName === 'IsSurveyLinkEnable') {
        this.IsSurveyLinkEnable = dataValue;
      } else if (dataName === 'IsSendCertificate') {
        this.IsSendCertificate = dataValue;
      } else if (dataName === 'Isknowledgebasestreaming') {
        this.Isknowledgebasestreaming = dataValue;
      } else if (dataName === 'IsAPIEnabled') {
        this.IsAPIEnabled = dataValue;
      } else if (dataName === 'IsHtmlReports') {
        this.IsHtmlReports = dataValue;
      } else if (dataName === 'IsMobileOptional') {
        this.IsMobileOptional = dataValue;
      } else if (dataName === 'IsEmailActivation') {
        this.IsEmailActivation = dataValue;
      } else if (dataName === 'IsDistanceLearning') {
        this.IsDistanceLearning = dataValue;
      } else if (dataName === 'LogoDimension') {
        this.LogoDimension = dataValue;
      } else if (dataName === 'IsNewCertificate') {
        this.IsNewCertificate = dataValue;
      } else if (dataName === 'IsInstituteHasGraceMarks') {
        this.IsInstituteHasGraceMarks = dataValue;
      } else if (dataName === 'KnowledgeSharing') {
        this.KnowledgeSharing = dataValue;
      } else if (dataName === 'IsSchoolStructure') {
        this.IsSchoolStructure = dataValue;
      } else if (dataName === 'IsInstituteHasReAttemptCount') {
        this.IsInstituteHasReAttemptCount = dataValue;
      } else if (dataName === 'IsBulkApprove') {
        this.IsBulkApprove = dataValue;
      } else if (dataName === 'IsWhiteLabel') {
        this.IsWhiteLabel = dataValue;
      } else if (dataName === 'BranchID') {
        this.BranchID = dataValue;
      } else if (dataName === 'Iscandidatenameshowtoexaminer') {
        this.Iscandidatenameshowtoexaminer = dataValue;
      } else if (dataName === 'IsShowOldReviewReport') {
        this.IsShowOldReviewReport = dataValue;
      } else if (dataName === 'IsReGenerateResultEnabled') {
        this.IsReGenerateResultEnabled = dataValue;
      }
      else if (dataName === 'IsEnabledArchiveDB') {
        this.IsEnabledArchiveDB = dataValue;
      } else if (dataName === 'AllowSaveStreaming') {
        this.AllowSaveStreaming = dataValue;
      } else if (dataName === 'IsMoodleIntegrationEnabled') {
        this.IsMoodleIntegrationEnabled = dataValue;
      } else if (dataName === 'IsProgrammingQues') {
        this.IsProgrammingQues = dataValue;
      } else if (dataName === 'IsInstituteHasRandomDates') {
        this.IsInstituteHasRandomDates = dataValue;
      } else if (dataName === 'CloudType') {
        this.CloudType = dataValue;
      } else if (dataName === 'HeaderBGColor') {
        this.HeaderBGColor = dataValue;
      } else if (dataName === 'HeaderLabelColor') {
        this.HeaderLabelColor = dataValue;
      } else if (dataName === 'LoginWithDocumentProof') {
        this.LoginWithDocumentProof = dataValue;
      } else if (dataName === 'Isway2communication') {
        this.Isway2communication = dataValue;
      } else if (dataName === 'TodaysExamStats') {
        this.TodaysExamStats = dataValue;
      } else if (dataName === 'IsAllowProfPhotoCompare') {
        this.IsAllowProfPhotoCompare = dataValue;
      } else if (dataName === 'IsMUInstitute') {
        this.IsMUInstitute = dataValue;
      } else if (dataName === 'IsAdvanceAISetting') {
        this.IsAdvanceAISetting = dataValue;
      }
      else if (dataName === 'IsOldAI') {
        this.IsOldAI = dataValue;
      } else if (dataName === 'IsMisReport') {
        this.IsMisReport = dataValue;
      } else if (dataName === 'Zoho_CustomerID') {
        this.Zoho_CustomerID = dataValue;
      } else if (dataName === 'IsAutoGeneratedQue') {
        this.IsAutoGeneratedQue = dataValue;
      } else if (dataName === 'IsFeedBack') {
        this.IsFeedBack = dataValue;
      } else if (dataName === 'RoleName') {
        this.RoleName = dataValue;
      } else if (dataName === 'isPartialMarksForMTP') {
        this.isPartialMarksForMTP = dataValue;
      } else if (dataName === 'IsSkillSet') {
        this.IsSkillSet = dataValue;
      } else if (dataName === 'Signup_Process_Completed') {
        this.Signup_Process_Completed = dataValue;
      } else if (dataName === 'Payment_Done') {
        this.Payment_Done = dataValue;
      } else if (dataName === 'IsLatexEditor') {
        this.IsLatexEditor = dataValue;
      } else if (dataName === 'AIQuestionCredit') {
        this.AIQuestionCredit = dataValue;
      } else if (dataName === 'IsExamwiseLogo') {
        this.IsExamwiseLogo = dataValue;
      }
      else if (dataName === 'IsAllowPsychometricQuestion') {
        this.IsAllowPsychometricQuestion = dataValue;
      } else if (dataName === 'ShowRankingDashboard') {
        this.ShowRankingDashboard = dataValue;
      } else if (dataName === 'IsGenerativeAI') {
        this.IsGenerativeAI = dataValue;
      }
    }
    catch (ex) {
      this.logservice.InsertExceptionLog(ex, 'Data Store Service', 'setData');
      console.log("Error in setData : " + ex);
    }
  }


  getData(dataName: string): string {
    try {
      if (dataName == 'AllowAudioRecognition') {
        return this.AllowAudioRecognition;
      } else if (dataName === 'SubscriptionStartDate') {
        return this.SubscriptionStartDate;
      } else if (dataName === 'IsAutoEvaluation') {
        return this.IsAutoEvaluation;
      } else if (dataName === 'InstituteLogo') {
        return this.InstituteLogo;
      }else if (dataName === 'InstituteUserEmailID') {
        return this.InstituteUserEmailID;
      }
      else if (dataName === 'BranchWiseLogoUpload') {
        return this.BranchWiseLogoUpload;
      }
      else if (dataName === 'InstituteUserID') {
        return this.InstituteUserID;
      } else if (dataName === 'IsValid') {
        return this.IsValid;
      } else if (dataName === 'UserName') {
        return this.UserName;
      } else if (dataName === 'UserType') {
        return this.UserType;
      } else if (dataName === 'LastLogin') {
        return this.LastLogin;
      } else if (dataName === 'InstituteID') {
        return this.InstituteID;
      } else if (dataName === 'InstituteName') {
        return this.InstituteName;
      } else if (dataName === 'PackageID') {
        return this.PackageID;
      } else if (dataName === 'SubscriptionEndDate') {
        return this.SubscriptionEndDate;
      } else if (dataName === 'Plan_Expiry_Date_At') {
        return this.Plan_Expiry_Date_At;
      } else if (dataName === 'CategoryID') {
        return this.CategoryID;
      }
      else if (dataName === 'DateDiffCount') {
        return this.DateDiffCount;
      }
      else if (dataName === 'LogoUrl') {
        return this.LogoUrl;
      }
      else if (dataName === 'QuestionCount') {
        return this.QuestionCount;
      }
      else if (dataName === 'No_of_Exams_Created') {
        return this.No_of_Exams_Created;
      }
      else if (dataName === 'No_of_Subjects_Created') {
        return this.No_of_Subjects_Created;
      } else if (dataName === 'No_of_Topics_Created') {
        return this.No_of_Topics_Created;
      } else if (dataName === 'Total_Exams_Assigned') {
        return this.Total_Exams_Assigned;
      } else if (dataName === 'Total_Exam_Schedules') {
        return this.Total_Exam_Schedules;
      } else if (dataName === 'UsersAdded') {
        return this.UsersAdded;
      } else if (dataName === 'Plan') {
        return this.Plan;
      } else if (dataName === 'ExamSessionsConducted') {
        return this.ExamSessionsConducted;
      } else if (dataName === 'InstituteMobile') {
        return this.InstituteMobile;
      } else if (dataName === 'IsDataEncrypted') {
        return this.IsDataEncrypted;
      } else if (dataName === 'IsInstituteHasBranches') {
        return this.IsInstituteHasBranches;
      } else if (dataName === 'Isparagraphbased') {
        return this.Isparagraphbased;
      } else if (dataName === 'IsUnlimitedExam') {
        return this.IsUnlimitedExam;
      } else if (dataName === 'IsAutoProctored') {
        return this.IsAutoProctored;
      } else if (dataName === 'IsPDFDownload') {
        return this.IsPDFDownload;
      } else if (dataName === 'IsInstituteHasImageBasedOptions') {
        return this.IsInstituteHasImageBasedOptions;
      } else if (dataName === 'IsInstituteHasExamCode') {
        return this.IsInstituteHasExamCode;
      } else if (dataName === 'IsMathJaxReports') {
        return this.IsMathJaxReports;
      } else if (dataName === 'ExamMonthAvailable') {
        return this.ExamMonthAvailable;
      } else if (dataName === 'IsDifficultyLevelwiseResult') {
        return this.IsDifficultyLevelwiseResult;
      } else if (dataName === 'IsMultiselect') {
        return this.IsMultiselect;
      } else if (dataName === 'IsSurveyLinkEnable') {
        return this.IsSurveyLinkEnable;
      } else if (dataName === 'IsSendCertificate') {
        return this.IsSendCertificate;
      } else if (dataName === 'Isknowledgebasestreaming') {
        return this.Isknowledgebasestreaming;
      } else if (dataName === 'IsAPIEnabled') {
        return this.IsAPIEnabled;
      } else if (dataName === 'IsHtmlReports') {
        return this.IsHtmlReports;
      } else if (dataName === 'IsMobileOptional') {
        return this.IsMobileOptional;
      } else if (dataName === 'IsEmailActivation') {
        return this.IsEmailActivation;
      } else if (dataName === 'IsDistanceLearning') {
        return this.IsDistanceLearning;
      } else if (dataName === 'LogoDimension') {
        return this.LogoDimension;
      } else if (dataName === 'IsNewCertificate') {
        return this.IsNewCertificate;
      } else if (dataName === 'IsInstituteHasGraceMarks') {
        return this.IsInstituteHasGraceMarks;
      } else if (dataName === 'KnowledgeSharing') {
        return this.KnowledgeSharing;
      } else if (dataName === 'IsSchoolStructure') {
        return this.IsSchoolStructure;
      } else if (dataName === 'IsInstituteHasReAttemptCount') {
        return this.IsInstituteHasReAttemptCount;
      } else if (dataName === 'IsBulkApprove') {
        return this.IsBulkApprove;
      } else if (dataName === 'IsWhiteLabel') {
        return this.IsWhiteLabel;
      } else if (dataName === 'BranchID') {
        return this.BranchID;
      } else if (dataName === 'Iscandidatenameshowtoexaminer') {
        return this.Iscandidatenameshowtoexaminer;
      } else if (dataName === 'IsShowOldReviewReport') {
        return this.IsShowOldReviewReport;
      } else if (dataName === 'IsReGenerateResultEnabled') {
        return this.IsReGenerateResultEnabled;
      }
      else if (dataName === 'IsEnabledArchiveDB') {
        return this.IsEnabledArchiveDB;
      } else if (dataName === 'AllowSaveStreaming') {
        return this.AllowSaveStreaming;
      } else if (dataName === 'IsMoodleIntegrationEnabled') {
        return this.IsMoodleIntegrationEnabled;
      } else if (dataName === 'IsProgrammingQues') {
        return this.IsProgrammingQues;
      } else if (dataName === 'IsInstituteHasRandomDates') {
        return this.IsInstituteHasRandomDates;
      } else if (dataName === 'CloudType') {
        return this.CloudType;
      } else if (dataName === 'HeaderBGColor') {
        return this.HeaderBGColor;
      } else if (dataName === 'HeaderLabelColor') {
        return this.HeaderLabelColor;
      } else if (dataName === 'LoginWithDocumentProof') {
        return this.LoginWithDocumentProof;
      } else if (dataName === 'Isway2communication') {
        return this.Isway2communication;
      } else if (dataName === 'TodaysExamStats') {
        return this.TodaysExamStats;
      } else if (dataName === 'IsAllowProfPhotoCompare') {
        return this.IsAllowProfPhotoCompare;
      } else if (dataName === 'IsMUInstitute') {
        return this.IsMUInstitute;
      } else if (dataName === 'IsAdvanceAISetting') {
        return this.IsAdvanceAISetting;
      }
      else if (dataName === 'IsOldAI') {
        return this.IsOldAI;
      } else if (dataName === 'IsMisReport') {
        return this.IsMisReport;
      } else if (dataName === 'Zoho_CustomerID') {
        return this.Zoho_CustomerID;
      } else if (dataName === 'IsAutoGeneratedQue') {
        return this.IsAutoGeneratedQue;
      } else if (dataName === 'IsFeedBack') {
        return this.IsFeedBack;
      } else if (dataName === 'RoleName') {
        return this.RoleName;
      } else if (dataName === 'isPartialMarksForMTP') {
        return this.isPartialMarksForMTP;
      } else if (dataName === 'IsSkillSet') {
        return this.IsSkillSet;
      } else if (dataName === 'Signup_Process_Completed') {
        return this.Signup_Process_Completed;
      } else if (dataName === 'Payment_Done') {
        return this.Payment_Done;
      } else if (dataName === 'IsLatexEditor') {
        return this.IsLatexEditor;
      } else if (dataName === 'AIQuestionCredit') {
        return this.AIQuestionCredit;
      } else if (dataName === 'IsExamwiseLogo') {
        return this.IsExamwiseLogo;
      }
      else if (dataName === 'IsAllowPsychometricQuestion') {
        return this.IsAllowPsychometricQuestion;
      } else if (dataName === 'ShowRankingDashboard') {
        return this.ShowRankingDashboard;
      } else if (dataName === 'IsGenerativeAI') {
        return this.IsGenerativeAI;
      }
      return '';
    }
    catch (ex) {
      this.logservice.InsertExceptionLog(ex, 'Data Store Service', 'getData');
      console.log("Error in getData : " + ex);
      return '';
    }
  }

  clearData(dataName?: string) {
    try {
      if (dataName === 'AllowAudioRecognition') {
        this.AllowAudioRecognition = '';
      } else if (dataName === 'SubscriptionStartDate') {
        this.SubscriptionStartDate = '';
      } else if (dataName === 'IsAutoEvaluation') {
        this.IsAutoEvaluation = '';
      } else if (dataName === 'BranchWiseLogoUpload') {
        this.BranchWiseLogoUpload = '';
      } 
      else if (dataName === 'InstituteUserEmailID') {
        this.InstituteUserEmailID = '';
      } else if (dataName === 'InstituteLogo') {
        this.InstituteLogo = '';
      }
      else if (dataName === 'InstituteUserID') {
        this.InstituteUserID = '';
      } else if (dataName === 'IsValid') {
        this.IsValid = '';
      } else if (dataName === 'UserName') {
        this.UserName = '';
      } else if (dataName === 'UserType') {
        this.UserType = '';
      } else if (dataName === 'LastLogin') {
        this.LastLogin = '';
      } else if (dataName === 'InstituteID') {
        this.InstituteID = '';
      } else if (dataName === 'InstituteName') {
        this.InstituteName = '';
      } else if (dataName === 'PackageID') {
        this.PackageID = '';
      } else if (dataName === 'SubscriptionEndDate') {
        this.SubscriptionEndDate = '';
      } else if (dataName === 'Plan_Expiry_Date_At') {
        this.Plan_Expiry_Date_At = '';
      } else if (dataName === 'CategoryID') {
        this.CategoryID = '';
      }
      else if (dataName === 'DateDiffCount') {
        this.DateDiffCount = '';
      }
      else if (dataName === 'LogoUrl') {
        this.LogoUrl = '';
      }
      else if (dataName === 'QuestionCount') {
        this.QuestionCount = '';
      }
      else if (dataName === 'No_of_Exams_Created') {
        this.No_of_Exams_Created = '';
      }
      else if (dataName === 'No_of_Subjects_Created') {
        this.No_of_Subjects_Created = '';
      } else if (dataName === 'No_of_Topics_Created') {
        this.No_of_Topics_Created = '';
      } else if (dataName === 'Total_Exams_Assigned') {
        this.Total_Exams_Assigned = '';
      } else if (dataName === 'Total_Exam_Schedules') {
        this.Total_Exam_Schedules = '';
      } else if (dataName === 'UsersAdded') {
        this.UsersAdded = '';
      } else if (dataName === 'Plan') {
        this.Plan = '';
      } else if (dataName === 'ExamSessionsConducted') {
        this.ExamSessionsConducted = '';
      } else if (dataName === 'InstituteMobile') {
        this.InstituteMobile = '';
      } else if (dataName === 'IsDataEncrypted') {
        this.IsDataEncrypted = '';
      } else if (dataName === 'IsInstituteHasBranches') {
        this.IsInstituteHasBranches = '';
      } else if (dataName === 'Isparagraphbased') {
        this.Isparagraphbased = '';
      } else if (dataName === 'IsUnlimitedExam') {
        this.IsUnlimitedExam = '';
      } else if (dataName === 'IsAutoProctored') {
        this.IsAutoProctored = '';
      } else if (dataName === 'IsPDFDownload') {
        this.IsPDFDownload = '';
      } else if (dataName === 'IsInstituteHasImageBasedOptions') {
        this.IsInstituteHasImageBasedOptions = '';
      } else if (dataName === 'IsInstituteHasExamCode') {
        this.IsInstituteHasExamCode = '';
      } else if (dataName === 'IsMathJaxReports') {
        this.IsMathJaxReports = '';
      } else if (dataName === 'ExamMonthAvailable') {
        this.ExamMonthAvailable = '';
      } else if (dataName === 'IsDifficultyLevelwiseResult') {
        this.IsDifficultyLevelwiseResult = '';
      } else if (dataName === 'IsMultiselect') {
        this.IsMultiselect = '';
      } else if (dataName === 'IsSurveyLinkEnable') {
        this.IsSurveyLinkEnable = '';
      } else if (dataName === 'IsSendCertificate') {
        this.IsSendCertificate = '';
      } else if (dataName === 'Isknowledgebasestreaming') {
        this.Isknowledgebasestreaming = '';
      } else if (dataName === 'IsAPIEnabled') {
        this.IsAPIEnabled = '';
      } else if (dataName === 'IsHtmlReports') {
        this.IsHtmlReports = '';
      } else if (dataName === 'IsMobileOptional') {
        this.IsMobileOptional = '';
      } else if (dataName === 'IsEmailActivation') {
        this.IsEmailActivation = '';
      } else if (dataName === 'IsDistanceLearning') {
        this.IsDistanceLearning = '';
      } else if (dataName === 'LogoDimension') {
        this.LogoDimension = '';
      } else if (dataName === 'IsNewCertificate') {
        this.IsNewCertificate = '';
      } else if (dataName === 'IsInstituteHasGraceMarks') {
        this.IsInstituteHasGraceMarks = '';
      } else if (dataName === 'KnowledgeSharing') {
        this.KnowledgeSharing = '';
      } else if (dataName === 'IsSchoolStructure') {
        this.IsSchoolStructure = '';
      } else if (dataName === 'IsInstituteHasReAttemptCount') {
        this.IsInstituteHasReAttemptCount = '';
      } else if (dataName === 'IsBulkApprove') {
        this.IsBulkApprove = '';
      } else if (dataName === 'IsWhiteLabel') {
        this.IsWhiteLabel = '';
      } else if (dataName === 'BranchID') {
        this.BranchID = '';
      } else if (dataName === 'Iscandidatenameshowtoexaminer') {
        this.Iscandidatenameshowtoexaminer = '';
      } else if (dataName === 'IsShowOldReviewReport') {
        this.IsShowOldReviewReport = '';
      } else if (dataName === 'IsReGenerateResultEnabled') {
        this.IsReGenerateResultEnabled = '';
      }
      else if (dataName === 'IsEnabledArchiveDB') {
        this.IsEnabledArchiveDB = '';
      } else if (dataName === 'AllowSaveStreaming') {
        this.AllowSaveStreaming = '';
      } else if (dataName === 'IsMoodleIntegrationEnabled') {
        this.IsMoodleIntegrationEnabled = '';
      } else if (dataName === 'IsProgrammingQues') {
        this.IsProgrammingQues = '';
      } else if (dataName === 'IsInstituteHasRandomDates') {
        this.IsInstituteHasRandomDates = '';
      } else if (dataName === 'CloudType') {
        this.CloudType = '';
      } else if (dataName === 'HeaderBGColor') {
        this.HeaderBGColor = '';
      } else if (dataName === 'HeaderLabelColor') {
        this.HeaderLabelColor = '';
      } else if (dataName === 'LoginWithDocumentProof') {
        this.LoginWithDocumentProof = '';
      } else if (dataName === 'Isway2communication') {
        this.Isway2communication = '';
      } else if (dataName === 'TodaysExamStats') {
        this.TodaysExamStats = '';
      } else if (dataName === 'IsAllowProfPhotoCompare') {
        this.IsAllowProfPhotoCompare = '';
      } else if (dataName === 'IsMUInstitute') {
        this.IsMUInstitute = '';
      } else if (dataName === 'IsAdvanceAISetting') {
        this.IsAdvanceAISetting = '';
      }
      else if (dataName === 'IsOldAI') {
        this.IsOldAI = '';
      } else if (dataName === 'IsMisReport') {
        this.IsMisReport = '';
      } else if (dataName === 'Zoho_CustomerID') {
        this.Zoho_CustomerID = '';
      } else if (dataName === 'IsAutoGeneratedQue') {
        this.IsAutoGeneratedQue = '';
      } else if (dataName === 'IsFeedBack') {
        this.IsFeedBack = '';
      } else if (dataName === 'RoleName') {
        this.RoleName = '';
      } else if (dataName === 'isPartialMarksForMTP') {
        this.isPartialMarksForMTP = '';
      } else if (dataName === 'IsSkillSet') {
        this.IsSkillSet = '';
      } else if (dataName === 'Signup_Process_Completed') {
        this.Signup_Process_Completed = '';
      } else if (dataName === 'Payment_Done') {
        this.Payment_Done = '';
      } else if (dataName === 'IsLatexEditor') {
        this.IsLatexEditor = '';
      } else if (dataName === 'AIQuestionCredit') {
        this.AIQuestionCredit = '';
      } else if (dataName === 'IsExamwiseLogo') {
        this.IsExamwiseLogo = '';
      }
      else if (dataName === 'IsAllowPsychometricQuestion') {
        this.IsAllowPsychometricQuestion = '';
      } else if (dataName === 'ShowRankingDashboard') {
        this.ShowRankingDashboard = '';
      } else if (dataName === 'IsGenerativeAI') {
        this.IsGenerativeAI = '';
      }

    }
    catch (ex) {
      this.logservice.InsertExceptionLog(ex, 'Data Store Service', 'clearData');
      console.log("Error in clearData : " + ex);
    }
  }
}
