import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/common/auth.service';
import { LogService } from "src/app/services/common/log.service";

@Component({
  selector: 'app-email-activation',
  templateUrl: './email-activation.component.html',
  styleUrls: ['./email-activation.component.scss']
})


export class EmailActivationComponent {

  instituteID: string;
  updateFormGroup: FormGroup;

  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private logService: LogService,
    private router: Router) {
  }

  ngOnInit() {
    try {
      this.route.params.subscribe(params => {
        this.instituteID = params['instituteID'];
        console.log('encryptioText:', this.instituteID);
      });

      if (this.instituteID != "0" && this.instituteID != "") {
        this.updateFormGroup = new FormGroup({
          InstituteID: new FormControl(this.instituteID)
        })
        this.authService.updateEmailActivation(this.updateFormGroup.value).pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            if (result.objStatusCode == 1) {
              Swal.fire({
                icon: 'success',
                text: 'Your email address has been verified successfully...!!!',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Go to Login',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  window.open('https://dashboard.eklavvya.com/#/login', '_blank');
                }
              });
            }
          })
      }
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex, 'Institute Registration', 'createNewInstituteRecord');
      console.log("Error in createNewInstituteRecord : " + ex);
    }
  }

  ngOnDestroy() {

    this.destroy$.next();
    this.destroy$.complete();

  }
}
