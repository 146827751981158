import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UpdatePasswordService {

  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  changeInstituteUserPasswordEklavvyaWebAPI(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}InstituteUser/ChangeInstituteUserPassword`, body, options);
  }
}
