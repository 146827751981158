<div class="height-overflow">
    <!-- <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid et_fullwidth_nav">
            
        </div>
    </nav> -->
    

    <div class="col-sm-12 mb-100">
        <!-- <div class="text-center col-sm-12">
            <span class="card" style="font-weight:bold;"></span>
        </div> -->
        <div class="margin_top_desktop container">
            <div class="row">
                <div class="col-sm-7 mt-4 mb-4 text-white hide-mobile">
                    <div class="mb-30">
                        <a class="navbar-brand" href="https://www.eklavvya.com/"><img id="img"
                            src="https://www.eklavvya.com/wp-content/uploads/2022/01/eklavvya.png" height="40" width="130"
                            alt="eklavvya logo"></a>
                    </div>
                    <div>
                        <h3>Your Trusted Partner For Assessments</h3>
                    </div>
                    <div>
                        <ul>
                            <li>Generative AI to Assess Skills With Ease</li>
                            <li>Assess Writing, Coding, Aptitude and More...</li>
                            <li>Automate Remote assessments with AI Proctoring</li>
                            <li>No credit card required. No strings attached.
                            </li>
                        </ul>
                    </div>
                    <div class="" style="max-width: 50%;">
                        <img class="img-fluid" src="../../../assets/images/client-icon/Highly-rated-on-the-top-review-platforms-removebg-preview.png"/>
                    </div>
                </div>
                <div class="col-sm-4 mt-3 ">
                    <div class="hide-desktop" style="padding: 5%;margin-bottom: 15%;">
                        <div class="mb-30">
                            <a class="navbar-brand" href="https://www.eklavvya.com/"><img id="img"
                                src="https://www.eklavvya.com/wp-content/uploads/2022/01/eklavvya.png" height="40" width="130"
                                alt="eklavvya logo"></a>
                        </div>
                        <div>
                            <h3 class="text-white">Your Trusted Partner For Assessments</h3>
                        </div>
                        </div>
                    <div class="card text-center">
                        
                        <div class="card-body card-display login-card"[ngClass]="{'hidden': !signUp}">
                            <div class="user-icon"><i class="fa fa-user-alt" style="
                                        font-size: 55px;
                                        color: #f3f5f9;
                                    "></i></div>
                            <div class="col-sm-12 pt-2" >
                                <!-- <div class="col-sm-2"></div> -->
                                <div class="col-sm-12 pt-1 btn btn-outline-google">
                                <!-- <span class="asterisk">{{existErrorGoogle}}</span> -->
                                <div class="mb-2 google-button" data-width="300" id="google-button"></div>
                                </div>
                                <!-- <div class="col-sm-2"></div> -->
                            </div>
                            <div class="text-capitalize mb-3 fs-bold " style="font-size: 18px;font-weight: 500;width: 100%;">OR
                            </div>
                            <div class="">
                                <input type="text" value="Sign up with email" class="btn btn-primary sign-up-email"
                                    (click)="toggleForm()">
                            </div>

                        </div>
                        <div class="card-body card-display" *ngIf="!signUp && showForm">
                            <div class="row">
                                <div class="col-sm-2">
                                    <a class="btn btn-light mt-0" (click)="back()">
                                        <i class="fa fa-arrow-left" aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <span class="asterisk col-md-12 fs-h ">{{existError}}</span>
                            <div class="asterisk text-start" style="position: relative;">
                                <span>*</span>Required fields
                            </div>
                            <form form class="login-form mt-2" [formGroup]="formGroup" style="text-align: left;">
                                <div class="col-sm-12 row form-group">
                                    <div class="input-group mb-3">
                                        <span class="bg-transparent"><i class="asterisk asterisk_">*</i></span>
                                        <!-- <span class="input-group-text bg-transparent">
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </span> -->
                                        <input type="text" class="form-control ps-15 bg-transparent"
                                            placeholder="Enter your Name" formControlName="userName" maxlength="50">

                                        <div class="asterisk col-md-12 fs-h mt-1"
                                            *ngIf="formSubmitted && formGroup.get('userName').hasError('required')">
                                            Please Enter Name !
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 row form-group">
                                    <div class="input-group mb-3">
                                        <span class="bg-transparent"><i class="asterisk asterisk_">*</i></span>
                                        <!-- <span class="input-group-text bg-transparent">
                                            <i class="fa fa-envelope" aria-hidden="true"></i></span> -->
                                        <input type="text" class="form-control ps-15 bg-transparent"
                                            placeholder="Enter E-mail" formControlName="emailID" maxlength="45">
                                        <div class="asterisk col-md-12 fs-h mt-1"
                                            *ngIf="formSubmitted && formGroup.get('emailID').hasError('required')">
                                            Please Enter Email ID !
                                        </div>
                                        <div class="asterisk col-md-12 fs-h mt-1" *ngIf="incoorectMail">
                                            Please Enter Valid Email ID !
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 row form-group">
                                    <div class="col-4" style="padding-right: 0px;">
                                        <div class="input-group mb-3">
                                            <select (change)="selectedCountryCode($event)" style="margin-left: 14px;"
                                                class="form-control select2 bg-transparent" aria-hidden="true">
                                                <option *ngFor="let country of countries" [value]="country.code">{{
                                                    country.name }} ({{ country.phoneCode }})</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="input-group mb-3">
                                            <input matInput type="text" [maxlength]="mobileMaxlength" minlength="10"
                                                class="form-control ps-15 bg-transparent" placeholder="Enter Mobile No."
                                                formControlName="mobileNumber" (keydown)="onKeyDown($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 row form-group">
                                    <div class="input-group mb-3">
                                        <span class="bg-transparent"><i class="asterisk asterisk_">*</i></span>
                                        <!-- <span class="input-group-text  bg-transparent">
                                            <i class="fa fa-lock" aria-hidden="true"></i></span> -->
                                        <input #NewPassword class="form-control ps-15 bg-transparent"
                                            placeholder="Enter Password"
                                            type="{{ isPasswordVisible ? 'text' : 'password' }}"
                                            formControlName="password" maxlength="25" />
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="toggle-new-password" mat-icon-button
                                                matSuffix (click)="togglePasswordVisibility()">
                                                <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off'
                                                    }}</mat-icon>
                                            </span>
                                        </div>
                                        <div class="asterisk col-md-12 fs-h mt-1"
                                            *ngIf="formSubmitted && formGroup.get('password').hasError('required')">
                                            Please Enter Password !
                                        </div>
                                        <!-- <div class="asterisk col-md-12 fs-h mt-1"
                                        *ngIf="strongPasswordError">
                                        Your password should contain 1 capital & 1 small case letter with minimum 8 character length
                                    </div> -->
                                        <!-- <div class="text-red-400 text-sm" *ngIf="passwordFormField?.dirty">
                                        <span class="block" [ngClass]="{'text-primary': passwordFormField?.value?.match('^(?=.*[A-Z])')}">At least one uppercase
                                                                letter.</span>
                                        <span class="block" [ngClass]="{'text-primary': passwordFormField?.value?.match('(?=.*[a-z])')}">At least one lowercase
                                                                letter.</span>
                                        <span class="block" [ngClass]="{'text-primary': passwordFormField?.value?.match('(.*[0-9].*)')}">At least one
                                                                digit.</span>
                                        <span class="block" [ngClass]="{'text-primary': passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">At least one
                                                                special
                                                                character.</span>
                                        <span class="block" [ngClass]="{'text-primary': passwordFormField?.value?.match('.{8,}')}">At least 8 characters
                                                                long.</span>
                                     </div> -->
                                    </div>
                                </div>
                                <div class="justify-center">
                                <ngx-recaptcha2 #captchaElem [siteKey]="CaptchaSiteKey" [size]="size"
                                    (success)="handleSuccess($event)" [hl]="lang" [theme]="theme" [type]="type">
                                </ngx-recaptcha2>
                                </div>
                                <span class="asterisk text-center">{{ErrorMessage}}</span>
                                <!-- <div class="col-sm-12 row mt-3">                    
                            <div class="input-group">
                                <span class="asterisk">*</span>
                                <img title="Verification Code" src="" style="border-width:0px;width: 150px; height: 40px; border-width: 0px; float: left;">
                                <img src="../../../assets/images/hip_reload.gif" style="width: 25px; height: 25px; margin-left: 1px; margin-top: 5px; margin-right: 3px;" alt="Not Readable? Load New"><br>
                                <input type="text" class="form-control" placeholder="Enter Captcha Code" style="height: 40px; width: 60px;"><br>
                            </div>
                            <span title="Captcha is required" class="asterisk text-center">Captcha is required</span>
                        </div> -->
                                <div class="form-group">
                                    <div class="col-12">
                                        <div class="checkbox">
                                            <span class="asterisk">*</span>
                                            <input type="checkbox" id="basic_checkbox_1">
                                            <span for="basic_checkbox_1" class=" col-md-12 fs-h mt-3 px-2">I accept Terms of Use and Privacy Policy of Site.</span>
                                        </div>
                                        <span *ngIf="checkboxError" class="asterisk col-md-12 fs-h">Please Accept Terms of Use and
                                            Privacy Policy of the Site.</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-12 text-center mt-2">
                                        <button type="submit" class="btn btn-primary"
                                            (click)="createNewInstituteRecord()" [disabled]="buttonClicked">CREATE FREE
                                            ACCOUNT</button>
                                    </div>
                                    <!-- /.col -->
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <div class="col-sm-7 px-5 mt-2 text-white hide-desktop">
                    
                    <div>
                        <ul>
                            <li>Generative AI to Assess Skills With Ease</li>
                            <li>Assess Writing, Coding, Aptitude and More...</li>
                            <li>Automate Remote assessments with AI Proctoring</li>
                            <li>No credit card required. No strings attached.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 hide-mobile" style="position: absolute;bottom: 0;background: #f3f5f9;padding: 0px 4%;">
        <div class="or-break">
              <span class="email-text" style="color: black;"><b style="color: #29688d !important;">500+ Businesses</b> in <b style="color: #29688d !important;">15+ countries</b> Trust Eklavvya</span>
        </div>
              <img src="../../../assets/images/client-icon/eklavvya-client-logo.png" class="img-fluid " title="clients"/>
          
    </div> 
    <div class="col-sm-12 hide-desktop" style="padding: 0px 6%;">
        <div class="">
              <span class="email-text" style="color: black;"><b style="color: #29688d !important;">500+ Businesses</b> in <b style="color: #29688d !important;">15+ countries</b> Trust Eklavvya</span>
        </div>
              <img src="../../../assets/images/client-icon/client-logo-mobile.png" class="img-fluid " title="clients"/>
    </div> 
</div>
<!-- Progress Loader -->
<div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#1f85d6" type="square-jelly-box" [fullScreen]="true">
      <p style="color: white"> Creating New Account. Please Wait... </p>
    </ngx-spinner>
  </div>